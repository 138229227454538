import { BaseSchema, ModelType, type ToOneRelationship, ToManyRelationship } from '~~/app/base/schemas/BaseSchema';
import InvoicingStatisticsCollection from '~/classes/InvoicingStatisticsCollection';
type ContactRelationships = {
    defaultVatRate: ToOneRelationship<ModelType.VAT_RATES>;
    invoicePaymentReminderGroup: ToOneRelationship<ModelType.INVOICE_PAYMENT_REMINDER_GROUPS>;
    worksites: ToManyRelationship<ModelType.WORKSITES>;
};

type ContactAttributes = {
    name: string;
    email: string;
    phone: string;
    vatNumber: string;
    locale: string;
    contactType: string;
    honorificPrefix: string;
    comments: string;
    options: Record<string, any>;
    address: Address | null;
};

type ContactMeta = {
    profitability: InvoicingStatisticsCollection;
};

export default class ContactSchema extends BaseSchema<ContactAttributes, ContactMeta, ContactRelationships> {
    protected type: ModelType = ModelType.CONTACTS;
}
